var vHome = new Vue({
    el: '#v-home',
    data: {
        huntlistProducts: []
    },
    ready: function () {
        this.getHuntlistProducts();
    },
    methods: {
        getHuntlistProducts: function () {
            var _self = this;

            this.$http.get(huntlistUrl).then(function (success) {
                if (success.ok) {
                    _self.huntlistProducts = success.data;
                } else {
                    console.log("Error when get huntlist products.");
                }
            }, function (error) {
                var data = error.data;

                if (error.status === 500) {
                    alert(data.error.message);
                } else {
                    console.log(data);
                }
            });
        },
        addToHuntlist: function (productCode, event) {
            if (typeof event != 'undefined') {
                var button = $(event.target);

                button.attr('disabled', 'disabled');
                button.addClass('loading');
            }

            var isWatch = !this.huntlistProducts.includes(productCode.toString());
            var _self = this;

            this.doWatch(productCode, isWatch, function (success) {
                if (typeof event != 'undefined') {
                    var button = $(event.target);

                    button.removeAttr('disabled');
                    button.removeClass('loading');
                }

                _self.getHuntlistProducts();
            });
        },
        doWatch: function (productCode, isWatched, postWatched) {
            this.$http.post(restockUrl, {
                code: productCode,
                watch: isWatched
            }).then(function (success) {
                postWatched(success);
            }, function (error) {
                alert(error.data.error.message);
            });
        }
    }
});

var vCountryPopup = new Vue({
    el: '#v-country-popup',
    data: {
        userLocation: {},
    },
    ready: function () {
        this.getUserLocation();
    },
    computed: {
        isCanShowPopup: function () {
            var _self = this;

            if (Object.keys(_self.userLocation).length > 0 && _self.userLocation.is_different_country) {
                return ( ! isSgSubdomain && _self.userLocation.country_code === sgCountryCode);
            }

            return false;
        }
    },
    methods: {
        setUserLocationCookie: function () {
            var currentDate = new Date();
            var expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0);

            document.cookie = "is_can_get_user_location=false;expires=" + expirationDate + ";path=/";
        },
        checkUserLocationCookie: function () {
            var name = "is_can_get_user_location=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');

            for(var i = 0; i < ca.length; i++) {
                var c = ca[i];

                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }

                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }

            return '';
        },
        getUserLocation: function () {
            var _self = this;

            if (this.checkUserLocationCookie() != 'false') {
                this.$http.get(getLocationUrl).then(function (success) {
                    if (success.ok) {
                        var location = success.data.location;

                        _self.userLocation = location;

                        try {
                            localStorage.setItem('userOriginCountry', JSON.stringify(location));
                        } catch (error) {
                            console.error('Failed to Store userOriginCountry.');
                        }

                        setTimeout(function () {
                            _self.showCountryPopup();
                        }, 400);
                    } else {
                        console.log("Error when get user location.");
                    }
                }, function (error) {
                    console.log(error.data);
                });
            } else {
                try {
                    var userLocation = localStorage.getItem('userOriginCountry');

                    _self.userLocation = JSON.parse(userLocation);
                } catch (error) {
                    console.error('Failed to get userOriginCountry.');
                }
            }
        },
        confirmLocation: function () {
            this.setUserLocationCookie();
            window.location.reload();
        },
        showCountryPopup: function () {
            var _self = this;

            if (_self.isCanShowPopup) {
                Popups.add($('#country-popup'));
            }
        }
    }
});

var productCurationSplideOption = {
    type: 'loop',
    perPage: 3,
    perMove: 1,
    pagination: false,
    rewind: true,
    autoplay: true,
};

var salesCategorySplideOption = {
    type: 'slide',
    perPage: 7,
    perMove: 1,
    arrows: false,
    pagination: false,
    autoplay: true,
    gap: '40px',
    breakpoints: {
        '1024': {
            perPage: 6,
            gap: '30px'
        },
        '560': {
            perPage: 4,
            gap: '15px',
        },
        '320': {
            perPage: 3
        }
    }
};

var featuredSellersSplideOption = {
    type: 'loop',
    perPage: 4,
    perMove: 1,
    pagination: false,
    rewind: true,
    autoplay: true,
    gap: '40px',
    breakpoints: {
        '768': {
            perPage: 3.5,
            gap: '20px',
        },
        '480': {
            perPage: 3,
            gap: '15px'
        }
    }
};

var ourPromiseSplideOption = {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    rewind: true,
    autoplay: true,
};

if ($('#flashSaleProductCuration').length) {
    new Splide('#flashSaleProductCuration', productCurationSplideOption).mount();
}

if ($('#salesCategorySlider').length) {
    new Splide('#salesCategorySlider', salesCategorySplideOption).mount();
}

if ($('#chanelProductCuration').length) {
    new Splide('#chanelProductCuration', productCurationSplideOption).mount();
}

if ($('#hermesProductCuration').length) {
    new Splide('#hermesProductCuration', productCurationSplideOption).mount();
}

if ($('#mostWantedProductCuration').length) {
    new Splide('#mostWantedProductCuration', productCurationSplideOption).mount();
}

if ($('#newArrivalsProductCuration').length) {
    new Splide('#newArrivalsProductCuration', productCurationSplideOption).mount();
}

if ($('#featuredSellers').length) {
    new Splide('#featuredSellers', featuredSellersSplideOption).mount();
}

if ($('#ourPromiseWrapperMobile').length > 0) {
    new Splide('#ourPromiseWrapperMobile', ourPromiseSplideOption).mount();
}

if ($('#limitedTimePromoProductCuration').length > 0) {
    new Splide('#limitedTimePromoProductCuration', productCurationSplideOption).mount();
}

var closetList = $('#closet-list');

if (closetList.length > 0) {
    var owl = closetList;

    if ($(window).width() > 959) {
        owl.owlCarousel({
            autoWidth: true,
            margin: 10,
            pagination: false,
        });

        $('#closetPrev').click(function () {
            owl.trigger('prev.owl.carousel');
            return false;
        });

        $('#closetNext').click(function () {
            owl.trigger('next.owl.carousel');
            return false;
        });
    }
}

var homeSlider = $('#home-banner-slider');
var homeSliderItem = $('#home-banner-slider li');

if (homeSlider.length > 0) {
    homeSlider.bxSlider({
        auto: (homeSliderItem.length > 1),
        pager: (homeSliderItem.length > 1),
        pause: 7000,
        touchEnabled: false
    });
}

$(document).ready(function () {
    // Show Popups Sequentially
    setTimeout(function () {
        showPopupSequentially();
    }, 800);
});
